<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="account">
		<div class="account-content">
			<div class="account-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">账号名称</span>
						<el-input class="common-row-ipt" placeholder="请输入账号名称" v-model="searchForm.userNum" />
					</div>
					<div class="common-row-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
					<div class="common-row-item">
						<el-button @click="actionFn('addBm')" style="margin-left: auto;">新增部门账号</el-button>
						<el-button @click="actionFn('addFh')">新增分会账号</el-button>
					</div>
				</div>
			</div>
			<div class="account-table">
				<el-table :data="tableData" border>
					<el-table-column width="50px" type="index" label="序号">
					</el-table-column>
					<el-table-column width="100px" prop="avatarUrl" label="账号头像">
						<template v-if="scope.row.avatarUrl" slot-scope="scope">
							<div class="table-action">
								<el-image fit="cover" class="table-action-avatar"
									:src="scope.row.avatarUrl?scope.row.avatarUrl:require('@/assets/images/common/avatar.png')"
									:preview-src-list="[scope.row.avatarUrl?scope.row.avatarUrl:require('@/assets/images/common/avatar.png')]">
								</el-image>
								<!-- <img class="table-action-avatar" :src="scope.row.avatarUrl" alt=""> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="userNum" label="账号名称">
					</el-table-column>
					<el-table-column prop="orgId" label="所属组织">
						<template slot-scope="scope">
							<span>{{scope.row.orgId != -1 ?scope.row.orgShortName: "浙江工业大学志愿者协会"}}</span>
						</template>
					</el-table-column>
					<el-table-column width="160px" prop="roleList" label="角色类型">
						<template v-if="scope.row.roleList" slot-scope="scope">
							<span>{{scope.row.roleList[0].name}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="createTime" label="创建时间">
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div v-if="scope.row.roleList[0].code != 'SUPER_ADMIN'||scope.row.userNum != 'admin'"
								class="table-action">
								<span @click="actionFn('edit', scope)">编辑</span>
								<el-divider direction="vertical"></el-divider>
								<span style="color: red;" v-if="scope.row.status == 'A'"
									@click="actionFn('disable', scope)">停用</span>
								<span v-else @click="actionFn('disable', scope)">启用</span>
								<el-divider direction="vertical"></el-divider>
								<span @click="actionFn('delete', scope)">删除</span>
							</div>
							<div v-else class="">
								<span style="cursor: not-allowed;">编辑</span>
								|
								<span style="cursor: not-allowed;">禁用</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogVisible" width="520px">
			<div slot="title" class="dialog-header">
				<span>{{dialogTitle}}</span>
				<img @click="closeDialog" src="@/assets/images/common/close.png">
			</div>
			<el-form :model="accountForm" :rules="accountRules" ref="accountForm" label-width="100px" class="">
				<el-form-item class="accountForm-item" label="账号头像:" prop="">
					<clickUpload :fileList="accountForm.avatarUrl" @beforeUpload="upLoadAvatar"
						:shapeUpLoad="'circle'" />
					<!-- <img class="accountForm-item-avatar" src="@/assets/images/layout/logo.png" alt=""> -->
				</el-form-item>
				<el-form-item class="accountForm-item" label="登录账号:" prop="account">
					<el-input :disabled="dialogTitle == '编辑部门账号' || dialogTitle == '编辑分会账号'"
						class="accountForm-item-ipt" v-model="accountForm.account" placeholder="请输入" />
				</el-form-item>
				<el-form-item class="accountForm-item" label="登录密码:"
					:prop="(dialogTitle == '编辑部门账号' || dialogTitle == '编辑分会账号')?'':'pwd'">
					<div class="accountForm-item-pwd">
						<el-input :type="!showPwd? 'password':'text'" v-model="accountForm.pwd"
							placeholder="请输入6-12位数字字母组合新密码"></el-input>
						<img @click="showPwdFn" v-if="!showPwd" src="@/assets/images/common/eye_close.png">
						<img @click="showPwdFn" v-else src="@/assets/images/common/eye_open.png">
					</div>
				</el-form-item>
				<el-form-item v-if="dialogTitle == '新增部门账号' || dialogTitle == '编辑部门账号'" class="accountForm-item"
					label="所属角色:" prop="role">
					<el-select class="accountForm-item-ipt" v-model="accountForm.role" placeholder="请选择">
						<el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="dialogTitle == '新增分会账号' || dialogTitle == '编辑分会账号'" class="accountForm-item"
					label="所属组织:" prop="origin">
					<el-select class="accountForm-item-ipt" v-model="accountForm.origin" placeholder="请选择">
						<el-option v-for="item in originList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button v-preventReClick @click="submitDialog" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getAccountList,
		getRoleList,
		addAccount,
		getDepartmentList,
		editAccount,
		detailAccount,
		disableAccount,
		deleteAccount,
	} from "@/api/system";
	import clickUpload from "@/components/clickUpload.vue";
	export default {
		components: {
			clickUpload
		},
		data() {
			const checkPwd = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入密码"));
				} else {
					let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
					if (!reg.test(value) || value.length < 6 || value.length > 12) {
						callback(new Error("密码必须为6-12位的数字和字母的组合"));
					} else {
						callback();
					}
					// let regx = /^[A-Za-z0-9]+$/
					// if (!regx.test(value) && value.length < 6 && value.length > 6) {
					// 	callback(new Error("请输入6~12位字母数字组合密码"));
					// }
				}
				callback();
			};
			return {
				tableData: [],
				searchForm: {},
				dialogVisible: false,
				dialogTitle: "",
				accountForm: {},
				accountRules: {
					avatarUrl: [{
						required: true,
						message: "请上传账号头像",
						trigger: "blur"
					}],
					account: [{
						required: true,
						message: "请输入登录账号",
						trigger: "blur"
					}],
					pwd: [{
						required: true,
						validator: checkPwd,
						trigger: "blur"
					}],
					role: [{
						required: true,
						message: "请选择所属角色",
						trigger: "blur"
					}],
					origin: [{
						required: true,
						message: "请选择所属组织",
						trigger: "blur"
					}],
				},
				showPwd: false,
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				roleList: [],
				originList: [],
			};
		},
		watch: {
			dialogVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["accountForm"].clearValidate()
						})
					}
				}
			},
			deep: true
		},
		mounted() {
			this.accountList()
		},
		methods: {
			// 获取分会列表
			departmentList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
					type: "SUB_COMP"
				}
				this.originList = [];
				getDepartmentList(params).then((res) => {
					if (res.code == 0) {
						this.originList = res.data.map(item => {
							return {
								label: item.name,
								value: item.id
							}
						})
					}
				})
			},
			// 获取角色列表
			roleListFn() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				this.roleList = [];
				getRoleList(params).then((res) => {
					if (res.code == 0) {
						let resRole = res.data.filter(item => (!item.superAdmin && item.code != 'SUPER_ADMIN'))
						if (resRole.length > 0) {
							this.roleList = resRole.map(item => {
								return {
									label: item.name,
									value: item.id
								}
							})
						}
					}
				})
			},
			accountList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					userNum: this.searchForm.userNum
				}
				this.tableData = [];
				getAccountList(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data.filter(item => item.userNum != 'admin' && (item.roleList[0]
							.code != "SUPER_ADMIN" || item.roleList[0].name != "超级管理员"))
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.accountList();
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.accountList()
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.accountList()
			},
			// 获取账号详情
			getAccountDetail(type, id) {
				detailAccount(id, {
					id: id
				}).then((res) => {
					if (res.code == 0) {
						let resData = res.data
						this.accountForm = {
							id: resData.id,
							avatarUrl: [resData.avatarUrl],
							account: resData.userNum,
							pwd: "",
						}
						if (type == 1) {
							this.$set(this.accountForm, "iamUserType", 1)
							this.$set(this.accountForm, "role", resData.roleList[0].id)
						} else {
							this.$set(this.accountForm, "iamUserType", 2)
							this.$set(this.accountForm, "origin", resData.orgId)
						}
					}
				})
			},
			actionFn(type, scope) {
				switch (type) {
					case "addBm":
						this.accountForm = {}
						this.$set(this.accountForm, "avatarUrl", [
							"http://upload.52xiangling.cn/upload/file/2208/f3f339bd31f54e7ea76e214c345d207d.png"
						])
						this.dialogTitle = "新增部门账号"
						this.roleListFn()
						this.dialogVisible = true
						break;
					case "addFh":
						this.accountForm = {}
						this.$set(this.accountForm, "avatarUrl", [
							"http://upload.52xiangling.cn/upload/file/2208/f3f339bd31f54e7ea76e214c345d207d.png"
						])
						this.dialogTitle = "新增分会账号"
						this.departmentList()
						this.dialogVisible = true
						break;
					case "edit":
						this.accountForm = {}
						this.$set(this.accountForm, "avatarUrl", [])
						if (scope.row.iamUserType == 1) {
							this.dialogTitle = "编辑部门账号"
							this.roleListFn()
						} else {
							this.dialogTitle = "编辑分会账号"
							this.departmentList()
						}
						this.getAccountDetail(scope.row.iamUserType, scope.row.id)
						this.dialogVisible = true
						break;
					case "disable":
						let confirmText = ""
						let confirmTitle = ""
						let messageText = ""
						let status = ""
						let params = {}
						if (scope.row.status == 'A') {
							confirmText = "确定停用此账号吗?"
							confirmTitle = "停用账号"
							params = {
								id: scope.row.id,
								status: 0
							}
							messageText = "账号停用成功"
							status = "I"
						} else {
							confirmText = "确定启用此账号吗?"
							confirmTitle = "启用账号"
							params = {
								id: scope.row.id,
								status: 1
							}
							messageText = "账号启用成功"
							status = "A"
						}
						this.$confirm(confirmText, confirmTitle, {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							disableAccount(scope.row.id, params).then((res) => {
								if (res.code == 0) {
									scope.row.status = status
									this.$message({
										type: 'success',
										message: messageText
									});
									this.$forceUpdate()
								}
							})
						}).catch(() => {});
						break;
					case "delete":
						this.$confirm("确定删除此账号吗?", "账号删除", {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							deleteAccount(scope.row.id, params).then((res) => {
								if (res.code == 0) {
									this.$message({
										type: 'success',
										message: "账号删除成功~"
									});
									this.accountList();
									this.$forceUpdate()
								}
							})
						}).catch(() => {});
						break;
				}
			},
			upLoadAvatar(props) {
				console.log("upLoadAvatar", props)
				this.accountForm.avatarUrl = props.fileData
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {
				this.$refs["accountForm"].validate(valid => {
					if (valid) {
						let formData = JSON.parse(JSON.stringify(this.accountForm))
						let params = {
							avatarUrl: formData.avatarUrl[0],
							userNum: formData.account,
							password: formData.pwd,
						}
						let message = ""
						if (this.dialogTitle == "新增部门账号" || this.dialogTitle == "新增分会账号") {
							if (this.dialogTitle == "新增部门账号") {
								this.$set(params, "iamUserType", 1)
								this.$set(params, "roleId", formData.role)
								message = "新增部门账号成功~"
								console.log("新增部门账号", params)
							} else if (this.dialogTitle == "新增分会账号") {
								this.$set(params, "iamUserType", 2)
								this.$set(params, "orgId", formData.origin)
								message = "新增分会账号成功~"
								console.log("新增分会账号", params)
							}
							addAccount(params).then((res) => {
								if (res.code == 0) {
									this.$message.success(message)
									this.pageIndex = 1;
									this.tableData = [];
									this.accountList();
									this.dialogVisible = false
								}
							})
						} else {
							if (!params.password) {
								delete params.password
							} else {
								let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
								if (!reg.test(params.password) || params.password.length < 6 || params.password
									.length > 12) {
									this.$message.error("密码必须为6-12位的数字和字母的组合")
									return
								}
							}
							this.$set(params, "id", formData.id)
							if (this.dialogTitle == "编辑部门账号") {
								this.$set(params, "iamUserType", 1)
								this.$set(params, "roleId", formData.role)
								message = "编辑部门账号成功~"
								console.log("编辑部门账号", params)
							} else if (this.dialogTitle == "编辑分会账号") {
								this.$set(params, "iamUserType", 2)
								this.$set(params, "orgId", formData.origin)
								message = "编辑分会账号成功~"
								console.log("编辑分会账号", params)
							}
							// console.log("params", params)
							editAccount(formData.id, params).then((res) => {
								if (res.code == 0) {
									this.$message.success(message)
									this.pageIndex = 1;
									this.tableData = [];
									this.accountList();
									this.dialogVisible = false
								}
							})
						}
					}
				})
			},
			showPwdFn() {
				this.showPwd = !this.showPwd
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #DCDFE6;
	}

	/deep/.el-button {
		height: 36px;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.account {
		.account-content {
			padding: 0 20px 20px 20px;

			.account-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}
				}
			}

			.account-table {
				margin-top: 20px;

				.table-action {
					display: flex;
					align-items: center;
					justify-content: center;

					.table-action-avatar {
						width: 40px;
						height: 40px;
						border-radius: 50%;
					}
				}
			}
		}

		.accountForm-item {
			margin-top: 20px;

			.accountForm-item-avatar {
				width: 45px;
				height: 45px;
				border-radius: 50%;
				cursor: pointer;
			}

			.accountForm-item-ipt {
				width: 350px;
			}

			.accountForm-item-pwd {
				width: 350px;
				position: relative;

				img {
					position: absolute;
					right: 10px;
					top: 12px;
					width: 16px;
					height: 16px;
					cursor: pointer;
				}
			}
		}

	}
</style>
